<template>
  <div class="notificationsMobile">
    <strong style="margin-left: 20px; font-size: 23px">Menu</strong>
    <div
      class="iconClose d-flex"
      style=""
      @click.prevent="$store.state.menuMobileScreen = false"
    >
      <i class="fa-solid fa-close"></i>
    </div>
    
    <div class="d-flex flex-column" style="padding: 20px">
      <div class="div mt-4" @click.prevent="ruta('Home')">
        <strong>Dashboard</strong>
      </div>
      <hr />
      <div class="div mb-4" @click.prevent="ruta('Services')">
        <strong>Servicios</strong>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {

    ruta(item) {
      this.$store.state.menuMobileScreen =false
      if (item != this.$route.name) {
        this.$router.push({ name: item });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.notificationsMobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  padding-block: 50px;

  .iconClose {
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 35px;
    border: 1px solid #333;
    height: 40px;
    width: 40px;
    border-radius: 50px;
  }

  .list-group-item {
    // border-bottom: 1px solid #c9c9c9 !important;
    border-bottom: none !important;
  }
}
</style>
