<template>
  <div
    class="modal fade"
    v-animate-css="'slideIn'"
    id="verServicioModal"
    tabindex="-1"
    style="overflow-y: scroll"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content" style="min-height: 790px">
        <LoaderBloque
          v-if="loaderBloque"
          mensaje="Cargando por favor espere..."
        />
        <div class="modal-header">
          <div
            class="d-flex flex-row"
            style="justify-content: space-between; width: 100%"
          >
            <h5 v-if="!$isMobile" class="modal-title">
              <img
                src="/img/marca/destino.png"
                style="width: 40px; margin-right: 20px"
              />
              Solicitud de servicio #{{ $store.state.mostrar?.id }}
            </h5>

            <h5 v-if="$isMobile" class="modal-title">
              <img
                src="/img/marca/destino.png"
                style="width: 40px; margin-right: 20px"
              />
              #{{ $store.state.mostrar?.id }}
            </h5>

            <strong
              v-if="$store.state.mostrar"
              class="d-flex flex-row"
              style="
                align-items: center;
                margin-right: 25px;
                padding-inline: 10px;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 13px 5px #eee;
              "
            >
              <i
                :class="{
                  'blinking-icon':
                    $store.state.mostrar.estado == 'En curso' ||
                    $store.state.mostrar.estado == 'En recogida' ||
                    $store.state.mostrar.estado == 'En entrega',
                }"
                class="mr-1 fa-solid fa-circle"
                style="font-size: 8px"
                :style="{
                  color: $parent.functionColorState($store.state.mostrar),
                }"
              ></i>
              <div>
                {{
                  $store.state.mostrar.estado == "curso"
                    ? "En curso"
                    : $store.state.mostrar.estado
                }}
              </div>
            </strong>
          </div>

          <button
            type="button"
            @click="$store.state.mostrar = null"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-light fa-xmark"></i>
          </button>
        </div>

        <!-- <div
          class="iconFilter d-flex flex-row btn btn-default"
          v-if="
            $isMobile &&
            $store.state.user.type != 'taller' &&
            $store.state.user.type != 'concesionario' &&
            !$store.state.mostrar?.conductor_id &&
            $store.state.mostrar?.estado != 'Fallido' &&
            $store.state.mostrar?.estado != 'Terminado'
          "
          style="
            border: 1px solid;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            margin-inline: 15px;
          "
          @click.prevent="openAsignar($store.state.mostrar)"
        >
          <small>Asignar conductor</small>
          <i class="fa-solid fa-car ml-3"></i>
        </div> -->

        <b-dropdown
          id="dropdown-right"
          right
          no-caret
          v-if="$isMobile"
          style="
            width: fit-content;
            float: right;
            justify-content: end;
            align-self: flex-end;
          "
        >
          <template #button-content>
            <i class="fa-sharp fa-solid fa-ellipsis"></i>
          </template>

          <b-dropdown-item-button
            v-if="
              $store.state.user.type != 'taller' &&
              $store.state.user.type != 'concesionario' &&
              !$store.state.mostrar?.conductor_id &&
              $store.state.mostrar?.estado != 'Fallido' &&
              $store.state.mostrar?.estado != 'Terminado'
            "
            @click.prevent="openAsignar($store.state.mostrar)"
            >Asignar</b-dropdown-item-button
          >
          <b-dropdown-item-button
            v-if="
              $store.state.user.type != 'taller' &&
              $store.state.user.type != 'concesionario' &&
              $store.state.mostrar?.conductor_id &&
              $store.state.mostrar?.estado != 'Terminado' &&
              $store.state.mostrar?.estado != 'Fallido'
            "
            @click.prevent="openAsignar($store.state.mostrar)"
            >Reasignar conductor</b-dropdown-item-button
          >
          <b-dropdown-item-button
            v-if="
              $store.state.user.type != 'taller' &&
              $store.state.user.type != 'concesionario' &&
              $store.state.mostrar?.estado != 'Fallido' &&
              $store.state.mostrar?.estado != 'Terminado'
            "
            @click.prevent="marcarFailed($store.state.mostrar)"
            >Marcar como fallida</b-dropdown-item-button
          >
          <a
            :href="
              $base.dominio +
              '/generate/report/' +
              $store.state.mostrar?.id +
              '/service'
            "
            target="_blank"
          >
            <b-dropdown-item-button
              v-if="$store.state.mostrar?.estado == 'Terminado'"
              >Descargar pdf reporte</b-dropdown-item-button
            >
          </a>
        </b-dropdown>

        <menu-service-data />

        <div class="modal-body" v-if="$store.state.mostrar" style="padding: 0">
          <!-- Informacion basica   -->
          <div class="d-flex flex-column" v-if="$store.state.mostrar">
            <!-- INFORMACION BASICA DEL SERVICIO  -->
            <informacion-google-map
              v-animate-css="'fadeIn'"
              v-if="menu == 1"
              :servicio="$store.state.mostrar"
            />

            <div style="padding: 10px">
              <informacion-basica
                v-animate-css="'fadeIn'"
                v-if="menu == 1"
                :servicio="$store.state.mostrar"
              />
              <informacion-data
                v-animate-css="'fadeIn'"
                v-if="menu == 4"
                :servicio="$store.state.mostrar"
              />
              <InformacionCliente
                v-animate-css="'fadeIn'"
                v-if="menu == 2"
                :servicio="$store.state.mostrar"
              />
              <informacion-vehiculo
                v-animate-css="'fadeIn'"
                v-if="menu == 3"
                :servicio="$store.state.mostrar"
              />

              <informacion-facturacion
                v-animate-css="'fadeIn'"
                v-if="menu == 5"
                :servicio="$store.state.mostrar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderBloque from "../../components/Laders/LoaderBloque.vue";

import toastr from "toastr";
import InformacionBasica from "@/components/Screen/Servicios/modalViewService/informacionBasica.vue";
import InformacionCliente from "../../components/Screen/Servicios/modalViewService/informacionCliente.vue";
import InformacionVehiculo from "@/components/Screen/Servicios/modalViewService/informacionVehiculo.vue";
import InformacionData from "@/components/Screen/Servicios/modalViewService/informacionData.vue";
import InformacionGoogleMap from "@/components/Screen/Servicios/modalViewService/informacionGoogleMap.vue";
import MenuServiceData from "@/components/Screen/Servicios/menuServiceData.vue";
import InformacionFacturacion from "@/components/Screen/Servicios/modalViewService/informacionFacturacion.vue";
export default {
  props: ["$store.state.mostrar"],
  mounted() {},
  components: {
    LoaderBloque,
    InformacionBasica,
    InformacionCliente,
    InformacionVehiculo,
    InformacionData,
    InformacionGoogleMap,
    MenuServiceData,
    InformacionFacturacion,
  },
  data() {
    return {
      loaderBloque: false,
      menu: 1,
    };
  },
  methods: {
    marcarFailed(item) {
      this.$swal({
        title: "Marcar como fallida",
        text: "¿Estás seguro de marcar como fallida la solicitud de servicio?",
        icon: "warning",
        dangerMode: true,
        showCancelButton: true,
        cancelButtonColor: "gray",
        confirmButtonColor: "green",
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.state.loader = true;
          let url = this.$base.dominio + "/servicio/failed";
          const datos = {
            id: item.id,
          };
          axios
            .post(url, datos)
            .then((res) => {
              this.$store.state.loader = false;
              console.log(res.data);
              location.reload();
            })
            .catch((e) => {
              this.$store.state.loader = false;
              toastr.error("No se puedo cambiar el estado del servicio");
              console.log(e);
            });
        }
      });
    },

    openAsignar(item) {
      this.closeModal();
      this.$store.state.mostrarAsignar = item;
      this.$store.state.proceso_asignar =
        item.estado == "Solicitado" ? "ASIGNAR" : "REASIGNAR";
      $("#asignarConductorServicio").modal("show");
    },
    closeModal() {
      $("#verServicioModal").modal("hide");
      this.$store.state.mostrar = null;
    },
  },
};
</script>
<style lang="scss">
#verServicioModal {
  .dropdown-toggle {
    background: white;
    border: 1px solid #8b8b8b;
    color: #000;
    width: 58px;
    justify-content: end;
    float: right;
    justify-items: end;
    font-size: 24px;
    padding: 0;
    margin: 12px;
  }
}
</style>
<style lang="scss" scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-icon {
  animation: blink 1s infinite;
}

.activeSelect {
  border: 2px solid #2196f3;
}

.listHover:hover {
  background: #fcfcfc;
  transition: 0.4s;
}
.botonLogo {
  border: 1px dashed #2196f3;
  border-radius: 10px;
  background: #eee;
  padding-block: 3px;
}
.image-container {
  width: 100px;
  height: 100px;
  border: 2px dashed #2196f3;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  color: #eee;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.doble {
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .espacio {
    padding-inline: 20px;
  }
}

.form {
  .form-group {
    width: 100%;
  }
}
</style>
