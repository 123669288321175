<template>
  <div>
    <input
      type="text"
      style="
        width: 100%;
        border: none;
        background: #ededed;
        border-radius: 10px;
        margin-bottom: 10px;
        padding-inline: 20px;
      "
      v-model="searchTerm"
      @input="handleSearch"
      :placeholder="placeholderSearch"
    />
    <table style="width: 100%">
      <thead>
        <slot name="thead"></slot>
      </thead>
      <tbody>
        <slot name="tbody" :items="data" v-if="data[0]"></slot>
        <div v-else>{{ ListaVacia ? ListaVacia : "No existen registros" }}</div>
      </tbody>
    </table>
    <pagination
      :current-page="currentPage"
      :total-pages="totalPages"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "./Pagination.vue";
import { debounce } from "lodash";

export default {
  props: {
    servicioConcesionario: {
      type: String,
    },
    servicioTaller: {
      type: String,
    },

    ListaVacia: {
      type: String,
    },
    user_filter: {
      type: Number,
    },
    placeholderSearch: {
      type: String,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 0, // Agrega la propiedad totalPages y asigna un valor inicial
      debouncedSearch: debounce(() => {
        this.fetchData();
      }, 500),
    };
  },

  mounted() {
    this.fetchData();
  },
  watch: {
    "$parent.$parent.coordinador_user_filter"(val) {
      if (val) {
        this.fetchData();
      }
    },
  },
  methods: {
    handleSearch() {
      this.debouncedSearch();
    },

    fetchData() {
      const datos = {
        page: this.currentPage,
        search: this.searchTerm,
        concesionario_id: this.$store.state.user.concesionario_id,
        taller_id: this.$store.state.user.taller_id,
        user_filter: this.user_filter,
        servicio_taller: this.servicioTaller,
        servicio_concesionario: this.servicioConcesionario,
        coordinador_user_filter: this.$parent.$parent.coordinador_user_filter
          ? this.$parent.$parent.coordinador_user_filter
          : null,
      };
      axios
        .post(this.url, datos)
        .then((response) => {
          this.data = response.data.data;
          this.totalPages = response.data.last_page;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
  },
};
</script>
